import logo1 from "../../Assets/logobgremove.png";
import Loginz from "../../Assets/loginzTrans.png";
import Loginz1 from "../../Assets/logingz_white.png";

import { InputGroup, Form, Col, Card, Button } from "react-bootstrap";
import { GoEye, GoEyeClosed } from "react-icons/go";
import { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UseFormValidations } from "../../FormValidation/UseFormValidations";
import "./Auth.css";
import { login } from "../../Config/ValidationSchema";
import { getList, notify, save } from "../../Config/SharedFunctions";
import { urls } from "../../Config/Consts";
import { updateLoggedInUser } from "../../Store/Action";
import { useDispatch } from "react-redux";
import { IoIosArrowRoundForward } from "react-icons/io";
import { returningValue } from "../../Config/SmallFunctions";

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [type, setType] = useState("password");
  const emailInputRef = useRef(null);
  const submit = async () => {
    let res = await save(urls?.Auth?.login, data);

    if (res?.data?.status == true) {
      let resData = res?.data?.data;
      if (resData?.policyStatus == !true) navigate(`/policyacceptency/${resData?.user?.empId}`);
      else {
        sessionStorage.setItem("token", resData?.token);
        sessionStorage.setItem("startTime", Date?.now())
        sessionStorage.setItem("policyAcceptency", resData?.policyStatus);
        sessionStorage.setItem("user", JSON.stringify(resData?.user));
        dispatch(updateLoggedInUser(resData?.user?.empId));
        let res1 = await getList(urls.userAccess.getUserAccessLinks, { subscriptionId: resData?.user?.roleId });
        let links = res1?.filter((v) => v?.status == "Active")?.map((e) => e.linkName);
        sessionStorage.setItem("accessLinks", JSON.stringify(links));
        navigate("/v1/dashboard");
      }

    }
  };

  const forgotPassword = async () => {
    if (data?.email && data?.email != "") {
      let body = { serverName: window.location.hostname, email: data?.email };
      let res = await save(urls?.Auth?.SendForgotPasswordMail, body);
    } else {
      notify(false, "Please enter email");
    }
  };
  const { data, errors, handleSubmit, formChange } = UseFormValidations({
    validationSchema: login,
    submit: submit,
  });

  useEffect(() => {
    const loggedInUser = sessionStorage.getItem("user");
    if (loggedInUser) navigate("/v1/dashboard");
  }, []);
  useEffect(() => {
    // Focus the email input field when the component mounts
    if (emailInputRef.current) {
      emailInputRef.current.focus();
    }
  }, []);
  return (
    <section className="vh-100 background-image">
      <div className="container-fluid h-custom">
        <div className="row d-flex justify-content-between">
          {/* <div className="container d-flex justify-content-start border">
              <span className="myStyle text-start border" style={{ fontSize: "25px", color: "whitesmoke" }}>
                <img src={Loginz1} width={100} height={50} />
              </span>
            </div> */}

          <Col lg={8} xs={12} md={6}>
            <img src={Loginz1} height={100} />
          </Col>
          <Col lg={4} xs={12} md={6} className="background-shade">
            <Card className="transparent-card  px-lg-5 py-lg-3 my-lg-5 my-md-2">
              <Card.Body className="transparent-card ">
                <div className="text-start mb-xl-5 mb-lg-4 mt-lg-5">
                  {/* <img src={Loginz} height={100} /> */}
                  <h1 className="text-white"> Welcome Back </h1>
                  <h5 className="text-white">Existing Member Login</h5>
                </div>

                <Form id="login-form" onSubmit={handleSubmit}>
                  <Form.Group className="mb-3">
                    <Form.Label className="text-white">Email </Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control size="lg"  ref={emailInputRef} type="email" required id="email" name="email"   className="input-heigt rounded-0" placeholder="Enter Email" onChange={formChange("")} />
                    </InputGroup>
                    {errors && errors?.email && <Form.Control.Feedback type="invalid">{errors?.email}</Form.Control.Feedback>}
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label className="text-white">Password</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control size="lg" type={type} name="password" required placeholder="Enter Password" className="input-heigt password rounded-0" onChange={formChange("")} value={returningValue(data?.password, "")} />
                      <InputGroup.Text className="rounded-0">
                        <span onClick={() => setType(type == "password" ? "text" : "password")} id="togglePassword">
                          {type == "password" ? <GoEyeClosed /> : <GoEye />}
                        </span>
                      </InputGroup.Text>
                      {errors && errors?.password && <Form.Control.Feedback type="invalid">{errors?.password}</Form.Control.Feedback>}
                    </InputGroup>
                  </Form.Group>
                  <div className="text-end ">
                    <p className="forgot-password text-danger">
                      <spna className="pointer" onClick={forgotPassword}>Forgot Password ?</spna>
                    </p>
                  </div>
                  <div className="text-center">
                    <Button onClick={handleSubmit} size="lg" className="btn-size mt-3 w-100 d-flex justify-content-`between```````````````````````````````````````````````````` rounded-0 align-items-center" variant="light" type="submit">
                      <span>Log in</span> <IoIosArrowRoundForward size={25} />
                    </Button>
                  </div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </div>
      </div>
    </section>
  );
}

export default Login;
